<template>
  <div>
    <v-container>
      <v-row style="margin-top: 50px" v-if="carregando">
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>

      <v-form v-else v-model="valid" ref="formulario" lazy-validation>
        <v-widget title="Contador" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="9">
                <v-text-field
                  persistentHint
                  outlined
                  autocomplete="off"
                  role="presentation"
                  required
                  autofocus
                  v-model="contador.nome"
                  name="nome"
                  label="Nome"
                  id="nome"
                  :rules="[rules.obrigatorio]"
                  @input="
                    (v) => {
                      contador.nome = v.toUpperCase();
                    }
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  persistentHint
                  outlined
                  autocomplete="off"
                  role="presentation"
                  v-model="contador.crc"
                  name="crc"
                  label="CRC"
                  id="crc"
                  :rules="[rules.obrigatorio]"
                  @input="
                    (v) => {
                      contador.crc = v.toUpperCase();
                    }
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field persistentHint outlined autocomplete="off" role="presentation" v-model="contador.cpf" name="cpf" label="CPF" id="cpf" :rules="!contador.cnpj ? [rules.obrigatorio, ...validarCpf] : []" v-mask="'###.###.###-##'"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  persistentHint
                  outlined
                  autocomplete="off"
                  role="presentation"
                  v-model="contador.cnpj"
                  name="cnpj"
                  label="CNPJ"
                  id="cnpj"
                  :rules="!contador.cpf ? [rules.obrigatorio, ...validarCnpj] : []"
                  v-mask="'##.###.###/####-##'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field persistentHint outlined autocomplete="off" role="presentation" v-model="contador.email" name="email" label="Email" id="email" :disabled="!!contador._id" :rules="[rules.obrigatorio, ...validarEmail]"></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="3">
                <campo-cep persistentHint outlined autocomplete="off" role="presentation" v-mask="'#####-###'" label="CEP" buscarcep @resultado="setEndereco" v-model="contador.cep" append-icon="map" data-cy="cep"></campo-cep>
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field persistentHint outlined autocomplete="off" role="presentation" label="Logradouro" v-model="contador.logradouro" :rules="validarLogradouro"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field persistentHint outlined autocomplete="off" role="presentation" label="Número" v-model="contador.numero" :rules="validarNumero"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field persistentHint outlined autocomplete="off" role="presentation" label="Complemento" v-model="contador.complemento"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field persistentHint outlined autocomplete="off" role="presentation" label="Bairro" v-model="contador.bairro" :rules="validarBairro"></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-autocomplete persistentHint outlined autocomplete="off" role="presentation" label="Estado" v-model="contador.estado" :items="constantes.ESTADOS_AUTOCOMPLETE" @change="filtrarCidadesEstado(contador.estado)"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="7">
                <v-autocomplete
                  persistentHint
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Cidade"
                  v-model="contador.codigoCidade"
                  @change="setCidade(contador.codigoCidade)"
                  :items="cidades"
                  :rules="validarCidade"
                  :disabled="cidades.length == 0 || $store.state.cidades.cidadesCarregando"
                  :loading="$store.state.cidades.cidadesCarregando"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field persistentHint outlined autocomplete="off" role="presentation" label="Celular" v-model="contador.celular" :rules="validarTelefone" v-mask="'(##) #####-####'"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field persistentHint outlined autocomplete="off" role="presentation" label="Telefone" v-model="contador.telefone" v-mask="'(##) ####-####'"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field persistentHint outlined autocomplete="off" role="presentation" label="E-mail Adicional" v-model="contador.emailAdicional" :rules="contador.emailAdicional ? validarEmail : []"></v-text-field>
              </v-col>
            </v-row>
          </div>

          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-btn depressed class="mr-2" :disabled="carregando" @click="cancelar">Cancelar</v-btn>
              <v-btn depressed color="primary" :loading="carregando" @click="salvar" :disabled="!valid">Salvar</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import conversor from '@/util/conversor';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioContador',
  directives: { mask },

  data: () => ({
    valid: false,

    rules: { ...regras },

    validarCnpj: [(v) => (v && v.length === 18 ? validador.cnpj(v) : false) || 'CNPJ Inválido'],
    validarCpf: [(v) => (v && v.length === 14 ? validador.cpf(v) : false) || 'CNPJ Inválido'],
    validarCep: [(v) => !!v || 'CEP Obrigatório'],
    validarLogradouro: [(v) => !!v || 'Logradouro Obrigatório'],
    validarNumero: [(v) => !!v || 'Número Obrigatório'],
    validarBairro: [(v) => !!v || 'Bairro Obrigatório'],
    validarCidade: [(v) => !!v || 'Cidade Obrigatória'],
    validarTelefone: [(v) => !!v || 'Preenchimento Obrigatório'],
    validarEmail: [(v) => (v && validador.email(v)) || 'E-mail Inválido'],

    constantes: constantes,
  }),

  computed: {
    ...mapState('contador', {
      contador: 'contador',
      carregando: 'carregando',
    }),

    ...mapState('cidades', {
      cidadesLista: 'cidades',
      cidadesCarregando: 'cidadesCarregando',
    }),

    ...mapGetters('cidades', {
      cidades: 'cidades',
    }),
  },

  methods: {
    ...mapActions('cidades', {
      filtrarCidadesEstado: 'filtrarCidadesEstado',
    }),

    async salvar() {
      if (this.$refs.formulario.validate()) {
        if (this.contador._id) {
          await this.$store.dispatch('contador/editar', this.contador);
        } else {
          await this.$store.dispatch('contador/adicionar', this.contador);
        }

        this.$router.push({ name: 'adminContadores' });
      }
    },

    cancelar() {
      this.$router.push({ name: 'adminContadores' });
    },

    setEndereco(endereco) {
      const dados = {
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        codigoEstado: conversor.ufParaCodigo(endereco.uf),
        estado: endereco.uf,
        cidade: endereco.localidade,
        codigoCidade: endereco.ibge,
        pais: 'Brasil',
        codigoPais: 1058,
      };

      this.$store.commit('contador/setContador', dados);
      this.filtrarCidadesEstado(endereco.uf);
    },

    setCidade(codigoCidade) {
      const cidade = this.cidadesLista.find((cidade) => cidade.codigo_cidade == codigoCidade);

      const dados = {
        cidade: cidade.cidade,
        codigoCidade: cidade.codigo_cidade,
        codigoEstado: cidade.codigo_estado,
        pais: 'Brasil',
        codigoPais: 1058,
      };

      this.$store.commit('contador/setContador', dados);
    },

    beforeRouteLeave(to, from, next) {
      this.$store.commit('cidades/setCidades', []);
      next();
    },
  },

  created() {
    if (this.$router.currentRoute.name == 'contadorEditar' && !this.contador._id) {
      this.$router.push({ name: 'adminContadores' });
    }
    this.valid = false;
  },

  mounted() {
    this.valid = false;
  },
};
</script>
